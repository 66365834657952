import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Product from './page/Product';
import Artigos from './page/Artigos';
import Home from './page/Home'
import UploadImage from './page/upload';
import Presente from './page/presentes';
import CriarArtigo from './page/criarArtigo'
import CriarProduto from './page/criarProduto'
import ArtigosPage from './page/ArtigosPage'
import './App.css';
import Nav from './components/Nav'
import Footer from './components/Footer'
import Sobremim from './page/sobremim'
import EditArtigos from  './page/editArtigos'
import FaleComigo from './page/falecomigo'
import LoginPage from './page/LoginPage'
import PosLogin from  './page/PosLogin'
import NotFound from './page/NotFound';
import EditProduto from './page/EditProduto'
import EditMimos from './page/editMimos'
import CreateMimo from './page/criarMimo'
function App() {
  return (
    <Router>
      <div>
       <Nav></Nav>

        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/conteudos" element={<Product />} /> */}
          <Route path="/artigos" element={<Artigos />} />
          <Route path="/artigos/:id" element={<ArtigosPage />} />
          <Route path="/artigosfiltrados/:categ" element={<Artigos />} />
          <Route path="/criarproduto" element={<CriarProduto />} />    
          <Route path="/CriarArtigo" element={<CriarArtigo />} />    
          <Route path="/Presentes" element={<Presente />} /> 
          <Route path="/Sobre" element={<Sobremim />} />       
          <Route path="/EditArtigo" element={<EditArtigos />}/>
          <Route path="/FaleComigo" element={<FaleComigo />}/>
          <Route path="/Login" element={<LoginPage />}/>
          <Route path="/opcoes" element={<PosLogin />}/>
          <Route path="/CriarArtigo/:id" element={<CriarArtigo />} />
          <Route path="*" element={<NotFound/>} />
          <Route path="/EditProduto" element={<EditProduto/>} />
          <Route path="/EditMimos" element={<EditMimos/>} />
          <Route path="/criarMimo" element={<CreateMimo/>} />
          
        </Routes>
        
        <Footer/>

      </div>
      
    </Router>
   
  );
}

export default App;
