import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import URL from '../Url_server'
import { useNavigate } from 'react-router-dom';
import { db}  from '../fb/firebase'; // ajuste o caminho conforme necessário

import { collection, query, where, getDocs } from 'firebase/firestore';
const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    var token = localStorage.getItem('token');
    if (token === "logado"){
      navigate('/opcoes')
    } 

  }, []);
  const handleLogin = async (e) => {
    e.preventDefault();
  
    // try {
    //   const response = await axios.post(URL + '/login', { 'email' :email, 'senha' :password });
    //   localStorage.setItem('token', "logado");
    //   alert('Login feito com sucesso!');
    //   navigate('/opcoes')
    // } catch (error) {
   
    //   alert('Erro no login ou credenciais inválidas!');
    // }


    try {
      const userQuery = query(collection(db, 'users'), where('email', '==', email));
      const userSnapshot = await getDocs(userQuery);

      if (userSnapshot.empty) {
        alert('Erro no login ou credenciais inválidas!');
        return;
      }

      const user = userSnapshot.docs[0].data();

      // Verifique a senha (é recomendável usar uma forma de hashing para senhas no back-end)
      if (user.senha !== password) {
        alert('Erro no login ou credenciais inválidas!');
        return;
      }
      console.log(user)
      // Se o login for bem-sucedido
      alert('Login feito com sucesso!');
      localStorage.setItem('token', "logado");
      navigate('/opcoes')

    } catch (err) {
      alert('Erro no servidor!');
      
    } 

  };

  return (
    <Container className="d-flex align-items-center justify-content-center mb-5 " >
      <Row className="w-100">
        <Col xs={12} md={6} lg={4} className="mx-auto">
          <h2 className="text-center mb-4">Login</h2>
          <Form onSubmit={handleLogin}>
            <Form.Group controlId="formBasicEmail" className="mb-3">
              <Form.Label>Endereço de email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Inserir email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formBasicPassword" className="mb-3">
              <Form.Label>Senha</Form.Label>
              <Form.Control
                type="password"
                placeholder="Senha"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Form.Group>

            <Button variant="success" type="submit" className="w-100">
              Login
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginPage;
