import React from 'react';
import './style/Footer.css'
import { Link } from 'react-router-dom';
function Footer() {
  
    return (
    <div className='footerHome'>
        
        <div className='SidesocialMediaFooterDiv' > 
        <div className='socialMediaFooterDiv'>
            
            <div>
               <img  className ="img_footer" src='Dra. Juliana Muzz.png'></img>
               </div>
               
        </div>
       
        </div>
        <div className='tSideSocialMediaDiv'>
              

                <div className='linksUteisDivFooter'>
                    <ul>
                    <h3 >Links Úteis</h3>
                    <Link className= "custom-link-foot"  to="/Artigos"><li>Blog</li></Link>
                    <Link className= "custom-link-foot"  to="/Sobre"><li>Sobre</li></Link>
                  
                    <Link  className= "custom-link-foot" to="/"> <li>Página Inicial</li></Link>
                  
                    {/* <Link  className= "custom-link-foot" to="/conteudos"><li>Conteúdos Exclusivos</li></Link> */}
                       
                        
                    <Link  className= "custom-link-foot" to="/FaleComigo"><li>Fale Comigo</li></Link>
                    </ul>

                </div>
                
        </div>
        <div className='copyRightFooter'>
            <p>© COPYRIGHT 2024 - Dra Juliana Muzz</p>
        </div>

    </div>
  );
}

export default Footer;
