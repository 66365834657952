import './style/artigos.css'
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaRegCalendarAlt } from 'react-icons/fa';
import Slider from 'react-slick';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import URL from '../Url_server'
import { flexbox } from '@mui/system';
import { db}  from '../fb/firebase'; // ajuste o caminho conforme necessário
import { collection, getDocs } from 'firebase/firestore';

const categoria = [
    {"nome": "Fome Emocional"},
    {"nome": "Saúde"},
    {"nome": "Bloqueios Emocionais"},
    {"nome": "Terapia"},
    {"nome": "Relacionamento"}
]

function Artigos() {
  const isMobile = useMediaQuery('(max-width:768px)');

  const { categ } = useParams(); // Obtém o id da URL

    const navigate = useNavigate();
    const [artigosBruto, setartigosBruto] = useState([]);
    const [artigos, setartigos] = useState([]);
    const [error, setError] = useState(null);
    const url = 'http://localhost:3030'
    const [selectedOption, setSelectedOption] = useState("");

    const getImageSrcFromHtml = (htmlString) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
        const img = doc.querySelector('img');
        return img ? img.src : null;
      };
    const goto = (id) =>{
        navigate(`/artigos/${id}`);
    }
    useEffect(() => {
      const fetchArtigos = async () => {
        // try {
        // //   const response = await axios.get( URL + '/buscarartigo/');
        // //   // Certifique-se de que a resposta é um array
        // //   if (Array.isArray(response.data)) {
           
         
        // //     ajuste(response.data);
        // //   } else {
        // //     console.error('A resposta da API não é um array:', response.data);
        // //     setError('A resposta da API não é um array.');
        // //   }
        // // } catch (error) {
        // //   setError(error.message);
        // //   console.error('Erro ao buscar os produtos:', error);
        // }
        try {
          const snapshot = await getDocs(collection(db, 'artigos'));
          if (snapshot.empty) {
            ajuste([]); // Se não houver artigos, defina um array vazio
          } else {
            const documents = snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            }));
            ajuste(documents);
          }
        } catch (err) {
           // Capture qualquer erro que ocorra
        } finally {
          // Define loading como false após a chamada
        }
      };
     
    
      const ajuste = (item) =>{
        const getImageSrcFromHtml = (htmlString) => {
            const parser = new DOMParser();
            const doc = parser.parseFromString(htmlString, 'text/html');
            const img = doc.querySelector('img');
            return img ? img.src : null;
          };
        
        var atualizado = item.map(artigo => {
            // Extraí o src da primeira imagem do HTML
            const imgSrc = getImageSrcFromHtml(artigo.html);
        
            // Retorna um novo objeto com o atributo img adicionado
            return {
              ...artigo,
              img: imgSrc
            };
          });
          
         setartigos(atualizado) 
         setartigosBruto(atualizado)
         if (categ){
          const filteredArray = atualizado.filter(artigo => artigo.categoria === categ);
          setartigos(filteredArray)
         
         }
       
        }
     fetchArtigos();
    
 
    }, []);
    const handleSelectChange = (event) => {
      const selectedValue = event.target.value;
      setSelectedOption(selectedValue);
      console.log(selectedValue)
      if (selectedValue == ""){
        setartigos(artigosBruto)
        console.log('ainda')
      }else{
        filtrar(selectedValue)
      }
     
    };
    const filtrar = (cat) =>{
     
      const filteredArray = artigosBruto.filter(artigo => artigo.categoria === cat);
      setartigos(filteredArray)
    

    }

  return (
   
      <div>
         <div className='headArtigosDiv'   >
         {isMobile ? (
            <img  className="mobileBlog" src='mobile5.png'/>
         ) :  (
          <img  className="mobileBlog" src='B5.png'/>
         )}
         </div>
         <div className='cabecalho_nome'>
         <h3 className='headArtigos'>
          Blog
            
            </h3>
            <span className='subTItleArt'>Artigos informativos, dicas práticas e histórias inspiradoras que te ajudarão a enfrentar os desafios do emagrecimento saudável.</span>
            </div>

            <nav className='categorias_blog'>
              
            {isMobile ? (
               <div className="form-group">
               <label  className=  "label" htmlFor="combobox">Categorias:</label>
               <select
                 id="combobox"
                 className="form-control"
                 value={selectedOption}
                 onChange={handleSelectChange}
               >
                 <option value="">Escolha uma categoria...</option>
                 {categoria.map(option => (
                   <option key={option.nome} value={option.nome}>
                     {option.nome}
                   </option>
                 ))}
               </select>
             </div>
              ) : (
                <ul>
                {categoria.map((te, index)=> (

                    <li   onClick={() => filtrar(te.nome)}>{te.nome}</li>
                ))}

                </ul>
              )}
            </nav>
           
            <div className='layoutDivArtigos'>
            {artigos.map(item => (
              <div className='layArtig'>
            <div className='artigCard' >
                <div className='artigCardimg'>
                    <img className='artigCardimgel' src={item.img}></img>
                
                
                </div>
                <div className='data_artg'>
                      
                        <span className='title_art'>{item.titulo}</span>
                <br></br>
                <span className='descript'>
                    {item.descricao}
                </span>

                <div className='btnArtg'>
               <button className='btn_artg' onClick={() => goto(item.id)}>   
   Confira Agora
    </button>
                </div>
                </div>
               
                    
               
                
                </div>
                </div>
 ))}

 <div className='ajuste_ar'>
  <hr></hr>
 </div>
                </div>
      </div>
 
   
  );
}

export default Artigos;
