import './style/presente.css'
import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import URL from '../Url_server'
import { db}  from '../fb/firebase'; // ajuste o caminho conforme necessário
import { collection, getDocs } from 'firebase/firestore';
function Presente() {
    const [presente, setpresente] = useState([]);
    const [error, setError] = useState(null);
    useEffect(() => {
        fetchProdutos()
    }, []);
    const fetchProdutos = async () => {
        // try {
        //   const response = await axios.get( URL + '/buscarmimo');
        //   if (Array.isArray(response.data)) {
        //     setpresente(response.data);
        //     console.log(response.data)
        //   } else {
        //     console.error('A resposta da API não é um array:', response.data);
        //     setError('A resposta da API não é um array.');
        //   }
        // } catch (error) {
        //   setError(error.message);
        //   console.error('Erro ao buscar os produtos:', error);
        // }
        try {
          const snapshot = await getDocs(collection(db, 'mimos'));
          if (snapshot.empty) {
            setpresente([]); // Se não houver artigos, defina um array vazio
          } else {
            const documents = snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            }));
            setpresente(documents);
          }
        } catch (err) {
           // Capture qualquer erro que ocorra
        } finally {
          // Define loading como false após a chamada
        }
      };
  
    return (
     
        <div className=''>
            <div className='alinhamento_DIV'>
            <h3>Meu Presente para você!</h3>
            <p>Criei esses recursos pensando em você,
para que possa começar a se familiarizar
com temas importantes como a fome
nervosa , suas emoções e
comportamentos.</p>
<p>São ferramentas que disponibilizo para te
auxiliar a desenvolver uma maior
autoconsciência - elas são um excelente
ponto de partida para sua jornada de
autoanálise e autodescoberta.</p>
<p>Os materiais que você encontrará aqui,
proporcionam insights valiosos sobre sua
mente e emoções, ajudando você a criar
estratégias mais eficazes para lidar com
os desafios do emagrecimento.</p>
<p>Você descobrirá maneiras de transformar
pensamentos negativos e
comportamentos automáticos em
atitudes mais positivas e conscientes.
</p>
<p>Dedique um tempo para explorar cada
recurso com calma e atenção.</p><p>
Lembre-se de que este é apenas o
começo de uma jornada mais ampla e
profunda.
</p><p>Cada pequena descoberta pode levar a
grandes mudanças em sua vida.</p>
<p>Estou aqui para apoiar você em cada
passo do caminho.</p><p>
Use esses instrumentos como um ponto
de partida e sinta-se encorajada a buscar
mais conhecimento e suporte.</p>
<p>Juntas, podemos transformar seu
cansaço e desmotivação em energia e
determinação, guiando você rumo a uma
vida mais saudável e equilibrada.
Aproveite esta oportunidade e dê o
primeiro passo para um novo começo.
</p>
<p>Com amor.</p>
<div className='div_assinatura'>
    <img src='assinatura.png' className='ajuste_assinatura'></img>
</div>
{presente.map((mimo)=> (
<div id='card_mimo_' className='Card_Home'>
    <div id= 'img_mimo_div' className='img_card_home'>
                            <img  id= 'img_mimo_'  src='presente.png'></img>
                      </div>
                      <div id= "title_card_present" className='title_card_home'>
                           <p>{mimo.titulo}</p>
                           <a href={mimo.url}><button >Acessar Mimo</button></a>
                      </div>

    </div>
   
))}
   
</div>


        </div>
        
   
     
    );
  }
  
  export default Presente;
  