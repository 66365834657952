import './style/product.css'
//http://localhost:3030/listarProdutos
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import URL from '../Url_server'
import {Link} from 'react-router-dom'


function EditMimos() {
  const [presente, setpresente] = useState([]);
  const [error, setError] = useState(null);
  const url = URL
  useEffect(() => {
   

    fetchProdutos();
  }, []);
  const fetchProdutos = async () => {
    try {
      const response = await axios.get( URL + '/buscarmimo');
      if (Array.isArray(response.data)) {
        setpresente(response.data);
        console.log(response.data)
      } else {
        console.error('A resposta da API não é um array:', response.data);
        setError('A resposta da API não é um array.');
      }
    } catch (error) {
      setError(error.message);
      console.error('Erro ao buscar os produtos:', error);
    }
  };

  const excluir = (id) =>{
    console.log(id)
    const userConfirmed = window.confirm("Tem certeza que deseja deletar este item?");
    console.log(id)
    if (userConfirmed) {
        try {
          
          const response =  axios.post( URL + `/Deletarmimo/` +  id, {
              params: {
                id: id
              }
            });

         console.log(response.data);
         window.alert('Deletado com sucesso!')
         fetchProdutos()
        } catch (error) {
          setError('Erro ao buscar o item');
          console.error(error);
        }
    };
}

  return (
   
      <div>
          <div className="ProductMargin">
          <div className='bodyEdit'>
          <h3 className='headEditArtigos'>Mimos</h3>

         <Link to="/criarMimo"><button className='btn_whstsapp'>Criar Mimo</button></Link>
         </div>
            <div className='ProductDivRange'>

           
            {presente.map((mimo)=> (
<div id='card_mimo_' className='Card_Home'>
    <div id= 'img_mimo_div' className='img_card_home'>
                            <img  id= 'img_mimo_'  src='presente.png'></img>
                      </div>
                      <div id= "title_card_present" className='title_card_home'>
                           <p>{mimo.titulo}</p>
                           <button id= "delete_mimo" onClick={() => excluir(mimo.id)}  >Deletar</button>
                      </div>

    </div>
   
))}
          
            
            <div className='productMarginDiv'>

            </div>

            </div>


          </div>



      </div>
 
   
  );
}

export default EditMimos;
