
import './style/sobre.css';
// import backgroundImage1 from 'back2.png';
// import backgroundImage2 from 'back2.png';
function Sobre() {
    
    const divStyle = {
        background: `url(${process.env.PUBLIC_URL}/back2.png) no-repeat, url(${process.env.PUBLIC_URL}/backSobre.png) repeat`,
        backgroundSize: '100% auto, auto',
      };

  return (
    
      <div className='secaoSobreHome' id = 'SobreHome'  style={divStyle}>
        <div className='ajuste_sobre_'>
        <div className='espacoSobreHome'>

        </div>
        <div className='textoSobreHome'>

        
        <div className='hsobrehometext'>
          <div className='title_sobre'>
            <h1 >Dra. Juliana Muzz</h1>
            <span className='subtitleSobre'>Psicoterapeuta Especialista em Comportamento Alimentar e Alimentação Consciente
            </span>
            </div>
        <p>
        Ensino mulheres, cansadas de tentar emagrecer, o passo a passo para construir uma relação saudável com a comida e, principalmente, consigo mesmas.

</p>
     
<p>Antes de mais nada, é preciso se conscientizar de que remédios, dietas, jejuns e exercícios físicos não transformam a mente – e é justamente a sua mente que te faz buscar continuamente conforto na comida.
</p>
<p>
Meu compromisso é oferecer estratégias para que você pense, sinta e se comporte com uma "mente magra."


</p>


    <p>Dê o primeiro passo para transformar sua vida hoje.

</p>
    <span>Com amor,  </span>
      <div className='img_sobre_home'>
        <img src='assinatura_so.png'></img>
      </div>

        </div>
        </div>
        <div className='fotoHome'>


        <div className='fotoHomeALign'> 
            <img src= 'Dra. Juliana Muzz Psicoterapeuta (2).png'></img>
        </div>

        
        </div>
        <div className='plataformasSobre'>
            <h3>Acesse as plataformas</h3>

            <a href='https://t.me/+L22GRhc413hjYjk8 '><span className='itemInstagramImg'> <img  className='instaFoto' src= "1.png"></img></span></a>

            <a><span className='itemInstagramImg'> <img  className='instaFoto' src= "3.png"></img></span></a>
            

        </div>
        <div className = 'hr_salkl'> <hr></hr></div>
      
      </div>
      

      </div>
  );
}

export default Sobre;
