import React, { useState } from 'react';
import axios from 'axios';
import URL from '../Url_server'

const CriarProduto = () => {
  const [titulo, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [descricao, setDescription] = useState('');
  const [url, setUrl] = useState('');
  const [img, setImage] = useState('');
  const [base64Image, setBase64Image] = useState('');
  const url_req = URL;
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setBase64Image(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const productData = {
        titulo,
        descricao,
      
      url,
      img: base64Image
    };
    console.log(productData)
    try {
      const response = await axios.post(url_req + '/criarProduto', productData);
      console.log('Produto criado com sucesso:', response.data);
      alert('sucesso')
    } catch (error) {
      console.error('Erro ao criar o produto:', error);
    }
  };

  return (
    <div className="container mt-5 mb-5 ">
      <h1>Criar Produto</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="titulo" className="form-label">Título</label>
          <input
            type="text"
            className="form-control"
            id="titulo"
            value={titulo}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        
        <div className="mb-3">
          <label htmlFor="descricao" className="form-label">Descrição</label>
          <textarea
            className="form-control"
            id="descricao"
            rows="3"
            value={descricao}
            onChange={(e) => setDescription(e.target.value)}
            required
          ></textarea>
        </div>
        <div className="mb-3">
          <label htmlFor="url" className="form-label">URL</label>
          <input
            type="url"
            className="form-control"
            id="url"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="img" className="form-label">Imagem</label>
          <input
            type="file"
            className="form-control"
            id="img"
            accept="image/*"
            onChange={handleImageChange}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">Criar Produto</button>
      </form>
    </div>
  );
};

export default CriarProduto;
