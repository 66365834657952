

import './style/falecomigo.css'
import React, { useState, useRef, useEffect } from 'react';

function FaleComigo() {
   
    return (
     
        <div  className='falecomigo_body'>
            <h3>Entre em contato</h3>
            <div className='contato_card'>
                <p>Email: juliswmuzz@gmail.com </p> 
                <p>Telefone: +39 389 245 2366</p>
                <a href='https://w.app/xveu0k'><button className='btn_whstsapp'>Fale comigo via Whatsapp</button></a>
                <br></br>
                <a href='https://t.me/+L22GRhc413hjYjk8'><button id="btn_tele" className='btn_whstsapp'>Entre no canal do Telegram</button></a>
            </div>
        </div>
        
   
     
    );
  }
  
  export default FaleComigo;
  