

import './style/artigoRecente.css'
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { db}  from '../fb/firebase'; // ajuste o caminho conforme necessário
import { collection, getDocs } from 'firebase/firestore';
import { FaRegCalendarAlt } from 'react-icons/fa';
import URL from '../Url_server'
function RecenteArtigo() {
  const navigate = useNavigate();
        const [artigos, setartigos] = useState([]);
        const isMobile = useMediaQuery('(max-width:768px)');
        const goto = (id) =>{
          navigate(`/artigos/${id}`);
      }
        const url = URL
        useEffect(() => {
            const fetchArtigos = async () => {
              // try {
              //   const response = await axios.get(url + '/buscarartigo');
              //   // Certifique-se de que a resposta é um array
              //   if (Array.isArray(response.data)) {
                 
              //       console.log(response.data)
              //     ajuste(response.data);
              //   } else {
              //   }
              // } catch (error) {
              //    console.error('Erro ao buscar os produtos:', error);
              // }

              try {
                const snapshot = await getDocs(collection(db, 'artigos'));
                if (snapshot.empty) {
                  ajuste([]); // Se não houver artigos, defina um array vazio
                } else {
                  const documents = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                  }));
                  ajuste(documents);
                }
              } catch (err) {
                 // Capture qualquer erro que ocorra
              } finally {
                // Define loading como false após a chamada
              }
            };
      
            const ajuste = (item) =>{
              const getImageSrcFromHtml = (htmlString) => {
                  const parser = new DOMParser();
                  const doc = parser.parseFromString(htmlString, 'text/html');
                  const img = doc.querySelector('img');
                  return img ? img.src : null;
                };
                console.log(artigos) 
              var atualizado = item.map(artigo => {
                  const imgSrc = getImageSrcFromHtml(artigo.html);
                  return {
                    ...artigo,
                    img: imgSrc
                  };
                });
                if (isMobile){
                  setartigos(atualizado.slice(0, 1)) 
                } else{
                  setartigos(atualizado.slice(0, 2)) 
                }
              
              }
           fetchArtigos();
           console.log(artigos)
          }, []);
     
    return (
    <div className='artRece'>
        <div className='ajustArt'>
       
        {artigos.map(item => (
           <div className='posArtgCa'>
            <div className='artigCard'>
                <div className='artigCardimg'>

                    <img className='artigCardimgel' src={item.img}></img>
                
                
                </div>
                <div className='data_artg'>
                           <span className='title_art'>{item.titulo}</span>
                <br></br>
                <span className='descript'>
                {item.descricao}  </span>

                <div className='btnArtg'>
                    <button className='btn_artg'  onClick={() => goto(item.id)}>Confira Agora</button>
                </div>
                </div>
                <div>
                    
                </div>
                

            </div>
            </div>
             ))}
           
           
        
        </div>

    </div>
  );
}

export default RecenteArtigo;
