import React, { useState } from 'react';
import './style/nav.css'
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Hamburger from 'hamburger-react';
import { useMediaQuery } from '@mui/material';
const header = {
    backgroundColor: '#15400e',
   //backgroundColor: '#15400e',
    textAlign: 'center',
   height: '110px'
  };
const navStyle = {
    backgroundColor: '#15400e',
    textAlign: 'center',
    margin: 'auto',
    height: '43px',
    padding: '5px',
    color: 'white'
    

  };
  const TextoAltaFont = styled.p`
  font-family: 'Alta', sans-serif;
  font-weight: 400;
`;


function Nav() {
  const isMobile = useMediaQuery('(max-width:768px)');
  const [isOpen, setIsOpen] = useState(false); 
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  return (
     <div>
       <header  className = 'header_' style={header}>
       <Link  className= "custom-link" to="/"><div className='img_logo_div'>
          <img className='img_logo_header' src="Dra. Juliana Muzz (3).png"></img>
        
        
        </div></Link>
       
        <img className='fitinha' src='fitinha.png'/>

       </header>
       {isMobile ? (
         <nav className='nav_mobile_headeers'>
           <div className="listaNav" onClick={toggleDropdown}><Hamburger className="icon_header"  /></div>
            {isOpen ? (
            <ul className='listaNav_mobile'>
                <li><Link  className= "custom-link" to="/">Home</Link></li>
                <li><Link className= "custom-link"  to="/Sobre">Sobre</Link></li>
                {/* <li><Link  className= "custom-link" to="/conteudos">Conteúdos Exclusivos</Link></li> */}
                <li><Link className= "custom-link"  to="/Artigos">Blog</Link></li>
                <li><Link className= "custom-link"  to="/Presentes">Mimos</Link></li>
                <li><Link  className= "custom-link" to="/FaleComigo">Fale Comigo</Link></li>
            </ul>
            ):(<span></span>)}
         </nav>
          ) : (
        <nav style={navStyle}>
            <ul className='listaNav'>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/Sobre">Sobre</Link></li>
                {/* <li><Link to="/conteudos">Conteúdos Exclusivos</Link></li> */}
                <li><Link to="/Artigos">Blog</Link></li>
                <li><Link to="/Presentes">Mimos</Link></li>
                <li><Link to="/FaleComigo">Fale Comigo</Link></li>
            </ul>
        </nav>
        )}
        <img className='fitinha' src='fitinha.png'/>
       
     </div>
    );
  }
  
  export default Nav;
  