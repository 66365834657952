import './style/artigos.css'
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaRegCalendarAlt } from 'react-icons/fa';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';
import URL from '../Url_server'
import {  Link } from 'react-router-dom';
import { textAlign } from '@mui/system';
import { db}  from '../fb/firebase'; // ajuste o caminho conforme necessário
import { collection, getDocs , doc, deleteDoc } from 'firebase/firestore';


const categoria = [
    {"nome": "Alta Performance"},
    {"nome": "Saúde"},
    {"nome": "Emagrecimento"}
]

function EditArtigos() {
    const navigate = useNavigate();
    const [artigosBruto, setartigosBruto] = useState([]);
    const [artigos, setartigos] = useState([]);
    const [error, setError] = useState(null);
    const url = 'http://localhost:3030'
  
    const getImageSrcFromHtml = (htmlString) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
        const img = doc.querySelector('img');
        return img ? img.src : null;
      };
    const goto = (id) =>{
        navigate(`/criarArtigo/${id}`);
    }
    useEffect(() => {
      
     fetchArtigos();
        
    }, []);
    const fetchArtigos = async () => {
        // try {
        //   const response = await axios.get( URL + '/buscarartigo/' );
        //   // Certifique-se de que a resposta é um array
        //   if (Array.isArray(response.data)) {
        //    console.log(response.data)
         
        //     ajuste(response.data);
        //   } else {
        //     console.error('A resposta da API não é um array:', response.data);
        //     setError('A resposta da API não é um array.');
        //   }
        // } catch (error) {
        //   setError(error.message);
        //   console.error('Erro ao buscar os produtos:', error);
        // }
        try {
          const snapshot = await getDocs(collection(db, 'artigos'));
          if (snapshot.empty) {
            ajuste([]); // Se não houver artigos, defina um array vazio
          } else {
            const documents = snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            }));
            ajuste(documents);
          }
        } catch (err) {
           // Capture qualquer erro que ocorra
        } finally {
          // Define loading como false após a chamada
        }
      };

      const ajuste = (item) =>{
        const getImageSrcFromHtml = (htmlString) => {
            const parser = new DOMParser();
            const doc = parser.parseFromString(htmlString, 'text/html');
            const img = doc.querySelector('img');
            return img ? img.src : null;
          };
          console.log(artigos) 
        var atualizado = item.map(artigo => {
            // Extraí o src da primeira imagem do HTML
            const imgSrc = getImageSrcFromHtml(artigo.html);
        
            // Retorna um novo objeto com o atributo img adicionado
            return {
              ...artigo,
              img: imgSrc
            };
          });
         setartigos(atualizado) 
         setartigosBruto(atualizado)
        }
    const excluir = async (id) =>{
        console.log(id)
        const userConfirmed = window.confirm("Tem certeza que deseja deletar este item?");

        if (userConfirmed) {
             try {
              
            //   const response =  axios.post( URL + `/DeletarArtigo/` +  id, {
            //       params: {
            //         id: id
            //       }
            //     });

            //  console.log(response.data);
            //  window.alert('Deletado com sucesso!')
            //  fetchArtigos()
            try {
              const docRef = doc(db, 'artigos', id);
              await deleteDoc(docRef);
        
              // Chame a função de sucesso passada como props, se existir
              // if (onDeleteSuccess) {
              //   onDeleteSuccess(articleId);
              // }
              
              alert(`Documento com ID ${id} deletado com sucesso.`);
            } catch (err) {
              console.error('Erro ao deletar documento:', err);
              // setError('Erro ao deletar documento');
            } finally {
              // setLoading(false);
            }
            } catch (error) {
              setError('Erro ao buscar o item');
              console.error(error);
            }
        };
    }
    const filtrar = (cat) =>{

      const filteredArray = artigosBruto.filter(artigo => artigo.categoria === cat);
      setartigos(filteredArray)

    }

  return (
   
      <div className='bodyEdit'>
         <h3 className='headEditArtigos'>Editar Artigos</h3>
         <div className='bodyEdit'>
         <Link to="/CriarArtigo"><button className='btn_whstsapp'>Criar Artigo</button></Link>
         </div>
            <div className='layoutDivArtigos'>
            {artigos.map(item => (
              <div className='layArtig'>
            <div id = "artigEdit" className='artigCard' >
                <div className='artigCardimg'>
                    <img className='artigCardimgel' src={item.img}></img>
                
                
                </div>
                <div className='data_artg'>
                      
                        <span className='title_art'>{item.titulo}</span>
                <br></br>
                <span className='descript'>
                    {item.descricao}
                </span>

                <div className='btnArtg'>
               <button className='btn_artg' onClick={() => goto(item.id)}>   
  Editar
    </button>
    
                </div>
                <div className='btnArtg'>
               <button  id= "deletar_btn" className='btn_artg' onClick={() => excluir(item.id)}>   
   Excluir
    </button>
    
                </div>
                </div>
               
                    
               
                
                </div>
                </div>
 ))}

 <div className='ajuste_ar'>
  <hr></hr>
 </div>
                </div>
      </div>
 
   
  );
}

export default EditArtigos;
