import React from 'react';

const NotFound = () => {
  return (
    <div>
      <h1>404 - Página Não Encontrada</h1>
      <p>Desculpe, mas a página que você está procurando não existe.</p>
    </div>
  );
};

export default NotFound;
