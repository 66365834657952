import './style/product.css'
//http://localhost:3030/listarProdutos
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import URL from '../Url_server'
import {Link} from 'react-router-dom'


function EditProduto() {
  const [produtos, setprodutos] = useState([]);
  const [error, setError] = useState(null);
  const url = URL
  useEffect(() => {
   

    fetchProdutos();
  }, []);
  const fetchProdutos = async () => {
    try {
      const response = await axios.get( URL + '/listarProdutos');
      // Certifique-se de que a resposta é um array
      if (Array.isArray(response.data)) {
        setprodutos(response.data);
        console.log(response.data)
      } else {
        console.error('A resposta da API não é um array:', response.data);
        setError('A resposta da API não é um array.');
      }
    } catch (error) {
      setError(error.message);
      console.error('Erro ao buscar os produtos:', error);
    }
  };
  const excluir = (id) =>{
    console.log(id)
    const userConfirmed = window.confirm("Tem certeza que deseja deletar este item?");
    console.log(id)
    if (userConfirmed) {
        try {
          
          const response =  axios.post( URL + `/DeletarProduto/` +  id, {
              params: {
                id: id
              }
            });

         console.log(response.data);
         window.alert('Deletado com sucesso!')
         fetchProdutos()
        } catch (error) {
          setError('Erro ao buscar o item');
          console.error(error);
        }
    };
}

  return (
   
      <div>
          <div className="ProductMargin">
          <div className='bodyEdit'>
          <h3 className='headEditArtigos'>Conteúdos Exclusivos</h3>

         <Link to="/criarproduto"><button className='btn_whstsapp'>Criar Conteúdo Exclusivo</button></Link>
         </div>
            <div className='ProductDivRange'>

           
{produtos.map((produto)=> (
   <div className='ProductListDiv'>
                <div className='ProductCardDiv' >
                <img src={produto.img}></img>
                  <div className='descriptionDvivProduct'>
                  <h4>{produto.titulo}</h4>
                  <p>
                  {produto.descricao}
                  </p>
                  <div className='btn_pd'>
                  <button id= "deletar_btn" onClick={() => excluir(produto.id)} className='btnCardFunc' >Deletar</button>
                  </div>       
                </div> 
                </div>
                </div>
))}

          
            
            <div className='productMarginDiv'>

            </div>

            </div>


          </div>



      </div>
 
   
  );
}

export default EditProduto;
