import React, { useState,useRef , useEffect} from 'react';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
import { useParams } from 'react-router-dom';

import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import URL from '../Url_server'
import { useNavigate } from 'react-router-dom';
const url = URL;
const uploadImage = async (base64Image) => {
    try {
      const response = await axios.post(url + '/buscarUrl', { base: base64Image });
      console.log(response)
      console.log(response.data)
      return response.data;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw new Error('Error uploading image');
    }
  };
  
  const processContent = async (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    const images = doc.querySelectorAll('img');
  
    for (const img of images) {
      const src = img.src;
  
      // Verifica se o src é uma base64
      if (src.startsWith('data:image/')) {
        const base64Image = src.replace(/^data:image\/\w+;base64,/, '');
        try {
          const url = await uploadImage(`data:image/png;base64,${base64Image}`);
          img.src = url; // Substitui o base64 pela URL
        } catch (error) {
          console.error('Error uploading image:', error);
        }
      }
    }
    
    
    return doc.body.innerHTML;
  };
const CriarArtigo = () => {
    const [content, setContent] = useState('');
    const { id } = useParams();
    const quillRef = useRef(null);
  const handleChange = (value) => {
    setContent(value);

  };
  const Click = () => {
    console.log(content)

  };
 
  const categorias = [
    {"nome": "Fome Emocional"},
    {"nome": "Saúde"},
    {"nome": "Bloqueios Emocionais"},
    {"nome": "Terapia"},
    {"nome": "Relacionamento"}
]

  const buscarURL = async (base64) => {
    try {
     
      const response = await axios.get( url + '/buscarUrl', {
          params: {
            img: base64
          }
        });
        console.log(response.data);
        return response.data;
    } catch (error) {
      
      console.error(error);
    }
  };
  
  const [error, setError] = useState(null);
  const [title, setTitle] = useState('');
  const [date, setDate] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
 const navigate = useNavigate();
    useEffect(() => {
        var token = localStorage.getItem('token');
       
        if (token === "logado"){
          //navigate('/opcoes')
        } else{
            navigate('/login')
        } 
        if (id) {
          console.log(id)
          const fetchItem = async () => {
            console.log('id')
          try {
            const ident = id; 
            const response = await axios.get( URL + `/buscar/artigo`, {
                params: {
                  id: ident
                }
              });
            var resultado = response.data;
            console.log(resultado)
            setTitle(resultado.titulo)
            setDescription(resultado.descricao)
            setContent(resultado.html)
            setCategory(resultado.categoria)
          } catch (error) {
           navigate('/editArtigo')
            console.error(error);
          }
         
        }
        fetchItem()
        }
      }, [id]);
  const handleSubmit = async (e) => {

    e.preventDefault();
    if (id){
      
      const conteudo = await processContent(content);
      setContent(conteudo)
      console.log(content)
  
      const artigo = {
        'id': id,
        'titulo': title,
        'data':date,
        'descricao' : description,
        'categoria': category,
        'html': conteudo
      };
     
  
      try {
       const response = await axios.put( url + '/editarArtigo', artigo);
        alert('Artigo editado com sucesso!');
        
        setTitle('');
        setDate('');
        setDescription('');
        setContent('')
        setCategory('');
      } catch (error) {
        console.error('Erro ao editar artigo:', error);
        alert('Erro ao editar artigo');
      }
    }

  
    
    else
    {
    console.log(content)
    const conteudo = await processContent(content);
    setContent(conteudo)
    console.log(content)

    const artigo = {
     'titulo': title,
      'data':date,
      'descricao' : description,
      'categoria': category,
      'html': conteudo
    };
   

    try {
     const response = await axios.post( url + '/criarArtigo', artigo);
      alert('Artigo criado com sucesso!');
      // Limpar os campos do formulário após a submissão
      setTitle('');
      setDate('');
      setDescription('');
      setContent('')
      setCategory('');
    } catch (error) {
      console.error('Erro ao criar artigo:', error);
      alert('Erro ao criar artigo');
    }
  }
  };

  return (
    <Container className='mb-5 '>
      <h1 className="mt-5 ">{id ? 'Atualizar Artigo' : 'Criar Novo Artigo'}</h1>
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="formTitle">
              <Form.Label>Título</Form.Label>
              <Form.Control
                type="text"
                placeholder="Digite o título do artigo"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </Form.Group>
          </Col>
          
        </Row>
        <Form.Group controlId="formDescription">
          <Form.Label>Descrição</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            placeholder="Digite a descrição do artigo"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </Form.Group>
        {/* <Form.Group controlId="formCategory" className="mt-3">
          <Form.Label>Categoria</Form.Label>
          <Form.Control
            type="text"
            placeholder="Digite a categoria do artigo"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            required
          />
        </Form.Group>
         */}
         
         <div className="btn-group" role="group" aria-label="Categoria">
      {categorias.map((categorie, index) => (
        <label key={index} className="m-3">
          <input
            type="radio"
            name="category"
            value={categorie.nome}
            checked={category === categorie.nome}
            onChange={(e) => setCategory(e.target.value)}
          />
          {categorie.nome}
        </label>
      ))}
    </div>
    
    <br></br>
        <Form.Label>Artigo:</Form.Label>
           <ReactQuill   
            ref={quillRef}
            modules={{
              toolbar: {
                container: [
                  [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                  [{size: []}],
                  ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                  [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                  ['link', 'image'],
                  ['clean']
                ],
               
              }
            }}
            formats={[
              'header', 'font', 'size',
              'bold', 'italic', 'underline', 'strike', 'blockquote',
              'list', 'bullet', 'indent',
              'link', 'image'
            ]
           }
            value={content} onChange={handleChange} />
        <Button variant="primary" type="submit" className="mt-3">
        {id ? 'Atualizar Artigo' : 'Criar Artigo'}
        </Button>
      </Form>
    </Container>
  );
};


export default CriarArtigo;
