import React, { useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import URL from '../Url_server';
import axios from 'axios';
const CreateMimo = () => {
  const [titulo, setTitulo] = useState('');
  const [url, setUrl] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const mimo = {
        titulo,
        url
    };
    console.log(mimo)
    try {
      const response = await axios.post(URL + '/criarMimo', mimo);
      console.log('Produto criado com sucesso:', response.data);
      alert('sucesso')
    } catch (error) {
      console.error('Erro ao criar o produto:', error);
    }
    // Lógica para criar o mimo
    console.log('Mimo criado:', { titulo, url });
  };

  return (
    <Container className=' mb-5'>
      <Row className="justify-content-md-center ">
        <Col xs={12} md={6}>
          <h2 className="text-center my-4">Criar Mimo</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formTitle">
              <Form.Label>Título</Form.Label>
              <Form.Control
                type="text"
                placeholder="Digite o título do mimo"
                value={titulo}
                onChange={(e) => setTitulo(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formUrl" className="mt-3">
              <Form.Label>URL</Form.Label>
              <Form.Control
                type="url"
                placeholder="Digite a URL do mimo"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                required
              />
            </Form.Group>

            <Button variant="success" type="submit" className="mt-4 w-100">
              Criar
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateMimo;
