import './style/artigoPage.css'
import React, { useState, useRef, useEffect } from 'react';
// import ReactQuill from 'react-quill';
import RecenteArtigo from '../components/recenteArtigo'
import { useNavigate, useParams } from 'react-router-dom';
//import 'react-quill/dist/quill.ssnow.css';
import URL from '../Url_server'
import { db } from '../fb/firebase'; // ajuste o caminho conforme necessário
import { doc, getDoc } from 'firebase/firestore';
import axios from 'axios';
const categoria = [
  {"nome": "Fome Emocional"},
  {"nome": "Saúde"},
  {"nome": "Bloqueios Emocionais"},
  {"nome": "Terapia"},
  {"nome": "Relacionamento"}
]
function ArtigosPage() {
    const { id } = useParams(); // Obtém o id da URL
    const [item, setItem] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchItem = async () => {
          try {
            const ident = id; 
            // const response = await axios.get( URL + `/buscar/artigo`, {
            //     params: {
            //       id: ident
            //     }
            //   });
            // setItem(response.data);

            const docRef = doc(db, 'artigos', id);
            const docSnap = await getDoc(docRef);

            if (!docSnap.exists()) {
              setError('Documento não encontrado');
            } else {
              setItem({ id: docSnap.id, ...docSnap.data() });
            }
          } catch (error) {
            setError('Erro ao buscar o item');
            console.error(error);
          }
        };
    
        fetchItem();
      }, [id]);

      const buscar = (categoria)=>{
        navigate('/artigosfiltrados/' + categoria)
      }
      if (error) return <div>{error}</div>;
      if (!item) return <div>Carregando...</div>;

    return (
     
        <div className='artigo_conteundo'>
           <div className='margem_artigo'>
           <h2 >{item.titulo}</h2>
            <div className="article-content" dangerouslySetInnerHTML={{ __html: item.html }} />  
           
          
           <div className='ass_pge_blog'>
            <img  src='../assinatura.png'/>
           </div>
           </div>
           
           <div className='categoria_artigo'>
            <h4>Categorias:</h4>
            <ol>
            {categoria.map(te=> (

<li onClick={()=>buscar(te.nome)}>{te.nome}</li>
))}

            </ol>
            
          
           </div>
           <div className='ajustePageArtigo'>
            <hr></hr>
           </div>
        </div>
        
   
     
    );
  }
  
  export default ArtigosPage;
  