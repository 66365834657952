

import './style/falecomigo.css'
import React, { useState, useRef, useEffect } from 'react';
import {  Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function PosLogin() {
    const navigate = useNavigate();
    useEffect(() => {
        var token = localStorage.getItem('token');
        console.log(token)
        if (token === "logado"){
          //navigate('/opcoes')
        } else{
            navigate('/login')
        } 
    
      }, []);
    return (
     
        <div  className='bodypos'>
            
                <Link to="/EditArtigo"><button className='btn_whstsapp'>Artigos</button></Link>
                <br></br>
                {/* <Link to="/EditProduto"><button  className='btn_whstsapp'>Produtos</button></Link> */}
                <br></br>
                <Link to="/EditMimos"><button  className='btn_whstsapp'>Mimos</button></Link>
        </div>
        
        
   
     
    );
  }
  
  export default PosLogin;
  