
// const admin = require('firebase-admin');
// const serviceAccount = require('../juliana-muzz-firebase-adminsdk-18cnc-6e9cee75b3.json');
// const { getFirestore } = require('firebase/firestore');
// admin.initializeApp({
//   credential: admin.credential.cert(serviceAccount),
//    storageBucket: 'gs://juliana-muzz.appspot.com'
// });

// const db = admin.firestore();
// const bucket = admin.storage().bucket();
// const auth = admin.auth();
//module.exports = {db,bucket,auth}  ;
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyBOhe6tm2seWLn52dNGz49z0bA5VUswYQY",
  authDomain: "juliana-muzz.firebaseapp.com",
  databaseURL: "https://juliana-muzz-default-rtdb.firebaseio.com",
  projectId: "juliana-muzz",
  storageBucket: "juliana-muzz.appspot.com",
  messagingSenderId: "18559346009",
  appId: "1:18559346009:web:8d5e2133a11fa399035848"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

export { db, storage, auth };