import React from 'react';
import './style/sobremim.css'

function Sobremim() {
  
    return (
    <div className='sobre_mim_'>
            <h2> Sobre mim</h2>

        <div>
        <p>
        Olá querida! Tudo bem?
</p>
        <p>Assim como você, eu também vivi presa na cultura da dieta por longos e dolorosos anos.</p>
        
        <p>Eu pesava 20 kg a mais, odiava me olhar no espelho, e já tinha tentado de tudo para emagrecer - chás, shakes, sopas, todos os tipos de dieta e inibidores de apetite. </p>

 <p>Mas eu estava focando no lugar errado – vivia obcecada pelo peso na balança, enquanto meus pensamentos giravam em torno da comida o tempo todo. Excluía alimentos, me privava, controlava calorias, e vivia uma eterna guerra entre querer muito emagrecer e me impor um caminho de restrições que não era saudável.
  </p>
  <p> Você sabia que existem dois tipos de fome? A fome física e a fome emocional – e é esta última que nos faz ganhar peso.

 </p>
 <p> Foi através de estudos que eu aprendi isso, e hoje estou aqui para te ensinar o passo a passo para se sentir emocionalmente forte e alcançar um corpo leve.


 </p>
 <p> Entenda que o seu peso não é o seu verdadeiro problema – ele é o resultado dos problemas que você somatizou ao longo dos anos.

 </p>
 <p> Por isso, o emagrecimento sustentável vai muito além de dietas restritivas e exercícios exaustivos – ele envolve o entendimento dos seus gatilhos emocionais, crenças limitantes e padrões que te levam a buscar conforto na comida.

 </p>
 <p> Pare agora de “comer as suas emoções” e transforme sua mente em uma aliada.
 </p>
 <p>
Chegou a hora de abandonar padrões emocionais e comportamentais desenvolvidos no passado, que ainda persistem no presente.

 </p>
 <p>Você está pronta para trilhar um caminho de autoconhecimento e transformação?

 </p>
 <p> Mude a maneira como vive seu processo de emagrecimento, como enxerga sua vida, como lida com as situações, com as pessoas e, principalmente, consigo mesma.


 </p>
 <p> Aprenda a se amar, se priorizar, emagrecer sem sofrimento e não voltar a engordar – treinando sua mente para se libertar da fome emocional.

 </p>

<p>A sua mudança começa de dentro para fora.

</p>
    <p>
    Você merece viver em paz e feliz com seu corpo, sua mente e suas emoções, e eu estou aqui para te guiar nesse caminho rumo à harmonia.


    </p>

  <p>Um abraço 🤍
  </p>
        </div>
        <div className='fotoHomeALign'> 
            <img src= 'Dra. Juliana Muzz Psicoterapeuta (4).png'></img>
        </div>

    </div>
  );
}

export default Sobremim;