import React , { useEffect, useState }from 'react';
import { Link } from 'react-router-dom';
import Carrossel from '../components/Carrossel' 
import Sobre from '../components/sobre'
import CarrosselProdutos from '../components/produtosHome'
import RecenteArtigo from '../components/recenteArtigo'
import { useMediaQuery } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './style/home.css'
function Home() {
  const [shaking, setShaking] = useState(false);
  const isMobile = useMediaQuery('(max-width:768px)');

  useEffect(() => {
    const interval = setInterval(() => {
      setShaking(true);
      setTimeout(() => {
        setShaking(false);
      }, 500); // Duração da animação (mesma que no CSS)
    }, 5000); // Intervalo de 5 segundos

    return () => clearInterval(interval);
  }, []);
  return (
   <div >

        <Carrossel/>
        <div className='SecaoCardsHome'>
            <div className='lineHome'>
                <div className='modelSecaoCardsHome'>
                  <div className='Card_Home'>
                      <div className='img_card_home'>
                            <img src='telegram.png'></img>
                      </div>
                      <div className='title_card_home'>
                           <p>Canal do telegram</p>
                           <a href='https://t.me/+L22GRhc413hjYjk8 '><button>Entrar no canal</button></a>
                      </div>


                  </div>
                  <div className='Card_Home'>
                  <div className='img_card_home'>
                            <img src='conteudo.png'></img>
                      </div>
                      <div className='title_card_home'>
                           <p>Conteúdos Exclusivos</p>
                           <Link to="/Artigos"><button>Acessar Conteúdos</button></Link>
                      </div>

      </div>
    <div className='Card_Home'>
    <div className='img_card_home'>
                            <img src='presente.png'></img>
                      </div>
                      <div className='title_card_home'>
                           <p>Mimos Especiais</p>
                           <Link to="/presentes"><button>Acessar Mimos</button></Link>
                      </div>

    </div>

            
              
              
               </div>
            </div>
            
        </div>
        <Sobre/>
        <img className='fitinha' src='fitinha.png'/>
        {/* <div className='headerprodutHome'>
          <h3>CONTEÚDOS EXCLUSIVOS</h3>
          <hr className='hr_prod'></hr>
        </div> */}
        {/* <CarrosselProdutos/> */}
        <div>
        <div className="float-button">
        <Link to='/presentes'> <button type="button" className={`btn btn-danger btn-lg rounded-circle ${shaking ? 'shake' : ''}`}>
     <i className="bi bi-gift-fill"></i>
      </button></Link> 
    </div>
        <div className='headerprodutHome'>
          <h3>ARTIGOS RECENTES</h3>
          <p>Acesse os todos os artigos <Link to="/artigos">aqui</Link></p>
        </div>
          <RecenteArtigo/>
        </div>
        <div className='ajusteeHome'> 

        </div>
   
   </div>
  );
}

export default Home;
